import React from 'react'
import MentionLégales from './MentionLégales';

function Mentions() {
  return (
    <div>
        <MentionLégales />
    </div>
  )
}

export default Mentions